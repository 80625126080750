import React from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'

import './aside-contact.scss'

const AsideContact = ({ ...props }) => {
    return(
        <Fade bottom cascade>
            <aside className="aside-contact">
                <div className="aside-contact__address">
                    <h4>Nosso endereço</h4>

                    <address>
                        Rua Barão de Itapetininga, 273<br />
                        9º andar, sala C.<br />
                        CEP 01042-913<br />
                        São Paulo, SP<br />
                    </address>

                    <p><a href="https://goo.gl/maps/nY6ZPo9XxUDPBs8G6" rel="noreferer">Como chegar</a></p>
                </div>

                <div className="aside-contact__phone">
                    <h4>Nosso telefone e whatsapp</h4>
                    <p className="phone">(11) 95486-8085</p>
                    <p><a href="https://wa.me/seunumerodetelefone?text=ola%20Reinserir">Chamar no Whats</a></p>
                </div>

                { props.work &&
                    <div className="aside-contact__curriculo">
                        <h4>Quer trabalhar na Reinseir <br />ou em algum parceiro?</h4>
                        <p><Link to="/">Cadastre seu currículo</Link></p>
                    </div>            
                }

            </aside>
        </Fade>
    )
}

export default AsideContact
